import axios from 'axios';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import './App.css';
import Preloader from './layouts/preloader/Preloader';
import NotFound from './pages/notFound/NotFound';
import { logoutAllTabs } from './components/src/header/profile-dropdown';
//import { useJwt, isExpired } from "react-jwt";
import PersonnelRegistration from './pages/personnellRegistration/PersonnelRegistration';
import StatusStatic from './pages/report/statusStatic/StatusStatic';
const Login = lazy(() => import("./pages/login/Login"));
const Personnel = lazy(() => import("./pages/personnel/personel/Personnel"));
const Help = lazy(() => import("./pages/help/Help"));
const Profile = lazy(() => import("./pages/profile-view/profile-view"));
const Home = lazy(() => import("./pages/home"));
const PersonnelSearch = lazy(() => import("./pages/personnel/personnelSearch/PersonnelSearch"));
const PersonnelSearchProfile = lazy(() => import("./pages/profile-view/profile-view"));
const EmployeePerformanceList = lazy(() => import("./pages/performance/EmployeePerformanceList"));
const PersonnelTarget = lazy(() => import("./pages/personnelTarget/PersonnelTarget"));

const Criteria = lazy(() => import("./pages/criteria/AddCriteria"));
const AddPerformance = lazy(() => import("./pages/addPerformance/AddPerformance"));
const PerformanceDetail = lazy(() => import("./pages/performanceDetail/PerformanceDetail"))
const SalaryExpectation = lazy(() => import("./pages/report/salaryExpectation/SalaryExpectation"))
const PersonnelDetail = lazy(() => import("./pages/personnel/personneldetail/PersonnelDetail"))
const PositionList = lazy(() => import("./pages/position/positionList/PositionList"))
const NegativeCandidate = lazy(() => import("./pages/negativeCandidate/NegativeCandidate"))
const AddNewTechnical = lazy(() => import("./pages/addNewParameter/AddNewTechnical"))
const TechnicalGroupReport = lazy(() => import("./pages/report/technicalGroupReport/TechnicalGroupReport"))
const CandidateForPosition = lazy(() => import("./pages/personnel/candidateForPosition/CandidateForPosition"));
const CvFormat = lazy(() => import("./pages/cvFormat/CvFormat"));
const FileUpload = lazy(() => import("./pages/fileUpload/fileUpload"));

const App: React.FC = () => {

  useEffect(() => {
    logoutAllTabs()
  }, [])

  const token = localStorage.getItem('token')
  const tokenDuration = localStorage.getItem('tokenDuration')

  if (token == null) {
    logoutAllTabs()
  }

  // const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
  //   () => localStorage.getItem('token') !== null
  // );

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  useEffect(() => {
    if (token != null) {
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
    }
  }, [token]);
  const [tokenDate, setTokenDate] = useState(new Date(tokenDuration?.split(' ')[0] ?? ''));

  // useEffect(() => {
  //   if ((window.location.href != window.location.origin + "/login")) {
  //     if (tokenDate.toDateString() <= new Date().toDateString()) {
  //       localStorage.removeItem('token');
  //      // setIsLoggedIn(false);
  //       // <Redirect to='/login' />;

  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if(token !=null){
  //     setIsLoggedIn(true);
  //   }
  //   else{
  //     setIsLoggedIn(false);
  //   }
  //   console.log('Token Değişti')
  // }, [token]);
  return (
    <div>
      <Router>

        <Suspense fallback={<Preloader />}>
          <Switch >
            <Route exact path="/" component={() => isLoggedIn ? <Home /> : <Redirect to='/login' />} />
            <Route path="/personnel" component={() => isLoggedIn ? <Personnel /> : <Redirect to='/login' />} />
            <Route exact path="/personnelregister" component={() => isLoggedIn ? <PersonnelRegistration /> : <Redirect to='/login' />} />
            <Route exact path="/help" component={() => isLoggedIn ? <Help /> : <Redirect to='/login' />} />
            <Route exact path="/profile/:id" component={() => isLoggedIn ? <Profile /> : <Redirect to='/login' />} />
            <Route exact path="/login" component={() => isLoggedIn == false ? <Login /> : <Redirect to='/' />} />
            <Route exact path="/personnelsearch" component={() => isLoggedIn ? <PersonnelSearch /> : <Redirect to='/login' />} />
            <Route exact path="/personnelSearch/profile/:id" component={() => isLoggedIn ? <Profile /> : <Redirect to='/login' />} />
            <Route exact path="/performancelist" component={() => isLoggedIn ? <EmployeePerformanceList /> : <Redirect to='/login' />} />
            <Route exact path="/personneltarget" component={() => isLoggedIn ? <PersonnelTarget /> : <Redirect to='/login' />} />
            <Route exact path="/addcriteria" component={() => isLoggedIn ? <Criteria /> : <Redirect to='/login' />} />
            <Route exact path="/addperformance" component={() => isLoggedIn ? <AddPerformance /> : <Redirect to='/login' />} />
            <Route exact path="/performanceDetail/:id" component={() => isLoggedIn ? <PerformanceDetail performanceId /> : <Redirect to='/login' />} />
            <Route exact path="/salaryExpectation" component={() => isLoggedIn ? <SalaryExpectation /> : <Redirect to='/login' />} />
            <Route exact path="/personnelDetail/:id/:userName" component={() => isLoggedIn ? <PersonnelDetail /> : <Redirect to='/login' />} />
            <Route exact path="/positionList" component={() => isLoggedIn ? <PositionList /> : <Redirect to='/login' />} />
            <Route exact path="/negativeCandidate" component={() => isLoggedIn ? <NegativeCandidate /> : <Redirect to='/login' />} />
            <Route exact path="/addNewParameter" component={() => isLoggedIn ? <AddNewTechnical /> : <Redirect to='/login' />} />
            <Route exact path="/technicalGroupReport" component={() => isLoggedIn ? <TechnicalGroupReport /> : <Redirect to='/login' />} />
            <Route exact path="/candidateForPosition" component={() => isLoggedIn ? <CandidateForPosition /> : <Redirect to='/login' />} />
            <Route exact path="/stateStatic" component={() => isLoggedIn ? <StatusStatic /> : <Redirect to='/login' />} />
            <Route exact path="/cvFormat" component={() => isLoggedIn ? <CvFormat /> : <Redirect to='/login' />} />
            <Route exact path="/uploadfile" component={() => isLoggedIn ? <FileUpload /> : <Redirect to='/login' />} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>

  );
  // else return (
  //   <div>
  //     <Router>
  //       <Suspense fallback={<Preloader />}>
  //         <Switch>
  //         <Route exact path="/" component={Login} />
  //         <Route exact path="/login" component={Login} />

  //           {/* </Switch>   
  //   <Switch> */}
  //           {/* <Route component={NotFound}/> */}
  //         </Switch>
  //       </Suspense>
  //     </Router>
  //   </div>
  // )
}

export default App;


